import { TableData } from "./types";

const filterTableData = (
  data: TableData[],
  selectedFilters: string[]
): TableData[] => {
  console.log(selectedFilters);
  if (selectedFilters.length === 0) {
    return data; 
  }

  const filteredData: TableData[] = data.filter((item) =>
    selectedFilters.every((filter) => item.hasOwnProperty(filter))
  );

  console.log(filteredData);

  return filteredData;
};

const getTableKey=(data:any)=>{
  const requiredData:any=[];
  data.map((item:any)=>{
    const id=item.id;
    const attributes = item.attributes;
    const generalInfo = attributes.general_info;
    const purchases=attributes.purchases;
    const inventory=attributes.inventory;
    const productInfo : any = {
      id,
      name: attributes?.product_name || "",
      productImage: attributes?.product_image?.url || "",
      purchaseDescription:purchases?.purchase_description,
      responsible: inventory?.responsible|| "",
      productCode: generalInfo?.product_code || 0,
      salesPrice: generalInfo?.sales_price || "",
      cost: generalInfo?.cost || "",
      category: generalInfo?.product_category || "",
      type: generalInfo?.product_type || "",
      qTYOnHand: purchases?.qty || 0,
      forecastedQTY: purchases?.forecasted_qty || 0,
      UMO: generalInfo?.purchased_uom || "",
      checked:false,
      delivery:purchases?.delivery_time_lead || 0,
    };
    requiredData.push(productInfo);
  })
  return requiredData;
}

const getGroupbyTableKey=(data:any)=>{
  const requiredData=data?.map((item:any)=>{
    let payload={
      "key": item.key,
      "total_sales_price": item.total_sales_price,
      data:[]
    }
      payload.data=getTableKey(item.products.data)
      return payload
  })
  return requiredData;
}

export {filterTableData,getTableKey,getGroupbyTableKey}