import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible ,validationerror} from "./assets";
import React from 'react';
import { Box, Typography } from '@material-ui/core';
export type token = string | null;
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
// Customizable Area End

export const configJSON = require("./config");


export interface Option {
  value: string;
  label: string;
}

type Bank = {
  id: string;
  type: string;
  attributes: {
    id: number;
    bank_name: string;
    bank_identifier_code: string;
    abm_code: string;
    phone_number: string;
    email_address: string;
    vat: string;
    address: string;
    country: string;
    state: string;
    city: string;
    postal_code: string;
    archive: boolean | null;
  };
};

type BankAccount = {
  id: string;
  type: string;
  attributes: {
    account_number: string;
    account_holder: string;
    account_holder_name: string;
    bank: string;
    company: string;
    clabe: string;
    currency: string;
    aba_routing: string;
    send_money: boolean;
    created_by: string;
    archive: boolean;
    created_at: string;
  };
};

interface BankAccountResponse {
  data: {
      id: string;
      type: string;
      attributes: {
          account_number: string;
          account_holder: string;
          account_holder_name: string;
          bank: string;
          company: string;
          clabe: string;
          currency: string;
          aba_routing: string;
          send_money: boolean;
          created_by: string;
          archive: boolean;
          created_at: string;
      };
  };
}


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: token;
  bankNameList:string[],
  saveClick: boolean;

  account_number:string;
  account_holder:string;
  account_holder_name:string;
  bank: string;
  company: string;
  clabe: string;
  currency: string;
  aba_routing: string;
  send_money: boolean;
  archive: boolean;
  anchorEl: HTMLElement | null;
  anchorElSetting: HTMLElement | null;
  bankAccountId:string;
  // Customizable Area End
}



interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BankAccountCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBankListingAPI:string="";
  createBankAccountMethodAPI: string = "";
  getSingleBankAccountAPI: string = ""; 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage), 
      getName(MessageEnum.RestAPIRequestMessage), 
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      bankNameList:[],
      saveClick: false,

      account_number:"",
      account_holder:"",
      account_holder_name:"",
      bank: "",
      company: "",
      clabe: "",
      currency: "",
      aba_routing: "",
      send_money: false,
      archive: false,
      anchorEl:null,
      anchorElSetting:null,
      bankAccountId:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
      runEngine.debugLog(
        "API Message Recived", message
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start
  currencyOptions: Option[] = [
    { value: 'Currency1', label: 'Currency1' },
    { value: 'Currency2', label: 'Currency2' }
  ];

  AHolderOptions: Option[] = [
    { value: 'AHolder1', label: 'AHolder1' },
    { value: 'AHolder2', label: 'AHolder2' }
  ];


  SettingOptions = [
    { name: "Archive" },
    { name: "Duplicate" },
  ];

  async componentDidMount() {
    const token = await getStorageData("TOKEN")
    this.setState({ token: token });
    if (!token) {
      this.props.navigation.navigate("EmailAccountLoginStyles")
    }
    else {
      const paramId = this.props.navigation.getParam("id")
      if (paramId) {
        this.fetchSingleBankAccountData(paramId)
      }
      this.fetchBankListData();
    }
  }

  receiveApi = (
    apiRequestCallId: string,
    responseJson: {
      data: Bank[] | BankAccount;
      message: string;
      meta: { total_pages: number; total_counts: number };
    }
  ) => {
    if (apiRequestCallId === this.getBankListingAPI) {
      this.handleGetBankNameResponse(
        responseJson.data as Bank[]
      )
    }
    if (apiRequestCallId === this.getSingleBankAccountAPI) {
      this.handleGetSingleBankAccountResponse(
        responseJson.data as BankAccount
      );
    }
    if (apiRequestCallId === this.createBankAccountMethodAPI) {
      if(responseJson?.message) {
        toast.success(responseJson?.message);
        setTimeout(() => this.props.navigation.navigate("BankAccountListing"), 5000)
      }
      else {
        toast.success(configJSON.bank_created_msg)
        setTimeout(() => this.props.navigation.navigate("BankAccountListing"), 5000)
      }
      this.handleGetSingleBankAccountID(responseJson as BankAccountResponse);
    }
  };

  handleGetSingleBankAccountID = (response: BankAccountResponse) => {
    const createdId = response.data.id;
    this.setState({
      bankAccountId: createdId
    })
  }

  handleGetBankNameResponse = (response: Bank[]) => {
    let bankList:string[]=[];
    response?.map((item: Bank)=>{
      bankList.push(item.attributes.bank_name)
      this.setState({
        bankNameList: bankList,
      })
    })
}

handleGetSingleBankAccountResponse = (
  data: BankAccount,
) => {
  if (data && data.attributes) {
    const bankAccount = data.attributes;
    this.setState({
      account_number: bankAccount.account_number,
      account_holder: bankAccount.account_holder,
      account_holder_name: bankAccount.account_holder_name,
      bank: bankAccount.bank,
      company: bankAccount.company,
      clabe: bankAccount.clabe,
      currency: bankAccount.currency,
      aba_routing: bankAccount.aba_routing,
      send_money: bankAccount.send_money,
      archive: bankAccount.archive
    })
  }
};

  handleValueChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    this.setState({
       ...this.state,
        [name]: event.target.value
    })
  }

  handleSettingsClickAway = () => {
    this.setState({ 
      anchorEl: null 
    });
  };

  handleClickSetting = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : event.currentTarget,
    });
  };

  handleResetBankAccount = () => {
    this.setState({
      account_number:"",
      account_holder:"",
      account_holder_name:"",
      bank: "",
      company: "",
      clabe: "",
      currency: "",
      aba_routing: "",
      send_money: false,
      archive: false,
    })
  }

  handleCheckFields=()=>{
    if (
      this.isStringNullOrBlank(this.state.account_number) ||
      this.isStringNullOrBlank(this.state.account_holder) ||
      this.isStringNullOrBlank(this.state.account_holder_name) ||
      this.isStringNullOrBlank(this.state.bank) ||
      this.isStringNullOrBlank(this.state.clabe) ||
      this.isStringNullOrBlank(this.state.currency) ||
      this.isStringNullOrBlank(this.state.aba_routing)
      ) {
      return false;
    }
    return true;
  }


  isStringNullOrBlank(pstr: string) {
    return pstr === null || pstr === '' || /^\s*$/.test(pstr);
  }


  handleDiscard = () => {
    this.props.navigation.navigate("BankAccountListing");
  }

  handleRedirectionToCreateScreen=()=>{
    this.props.navigation.navigate("BankAccountCreation")
  }

  handleRedirectionToEditScreen=()=>{
    const paramId = this.props.navigation.getParam("id")
    this.props.navigation.navigate("BankAccountCreation1", { id: paramId });
  }


  handleSwitchToggle = () => {
    this.setState({ send_money: !this.state.send_money });
  };

  handleErrorMessage = (type: string, show: boolean = false) => {
    if (this.state.saveClick || show) {
      let flabel: string | boolean = '';
      switch (type) {
        case 'account_number': flabel = this.showErrorMessage(this.state.account_number, 'Please enter valid account number'); break;
        case 'account_holder': flabel = this.showErrorMessage(this.state.account_holder, 'Please select valid account holder'); break;
        case 'account_holder_name': flabel = this.showErrorMessage(this.state.account_holder_name, 'Please enter valid account holder name'); break;
        case 'bank': flabel = this.showErrorMessage(this.state.bank, 'Please select bank'); break;
        case 'clabe': flabel = this.showErrorMessage(this.state.clabe, 'Please enter valid clabe'); break;
        case 'currency': flabel = this.showErrorMessage(this.state.currency, 'Please select valid currency'); break;
        case 'aba_routing': flabel = this.showErrorMessage(this.state.aba_routing, 'Please enter valid ABA/routing'); break;
        default:
          break;

      }
      return this.handleLabel(flabel)
    } else {
      return ''
    }

  }

  showErrorMessage = (state: string, smsg: string) => {
    return !state && smsg;
  }

  handleLabel = (flabel: string | boolean) => {
    return <>
      {
        flabel ? this.handleErrorLabel(flabel) : flabel
      }
    </>
  }

  handleErrorLabel = (flabel: string | boolean) => {
    return <Box className='flex relative' >
      <img className='error-text-img' src={validationerror} />
      <Typography className='error-text'>{flabel}</Typography>
    </Box>
  }

  fetchBankListData = () => {
    const token = this.state.token;
    this.getBankListingAPI = this.apiCallHandle(
      token,
      `/bx_block_cfbankcashrecitevoucher4/banks?page=1&per_page=10`,
      "GET"
    );
  };

  fetchSingleBankAccountData = (bankId: string) => {
    const token = this.state.token;
    this.getSingleBankAccountAPI = this.apiCallHandle(
      token,
      `/bx_block_cfbankcashrecitevoucher4/bank_accounts/${bankId}`,
      "GET"
    );
  };

  errorTextClass = (field: string, value: string) => {
    if (this.handleErrorMessage(field) && !value) {
      return 'error-text1';
    } else {
      return 'main-color';
    }
  }

  errorFocusClass = (field: string, value:string ) => {
    if (this.handleErrorMessage(field) && !value) {
      return 'error-focus';
    } else {
      return '';
    }
  }

  mainErrorBox = () => {
    return <Box className={`flex error-main-box ${!(this.state.saveClick && ((!this.handleCheckFields()))) && 'display-none'}`}>
      <img src={validationerror} />
      <Typography className='error-main-text'>Some of the fields are incorrect or invalid. Please enter the valid details to continue.</Typography>
    </Box>
  }

  handleSave = () => {
    
    const token = this.state.token;

    const formdata= new FormData();
    formdata.append("data[account_number]", this.state.account_number)
    formdata.append("data[account_holder]", this.state.account_holder)
    formdata.append("data[account_holder_name]", this.state.account_holder_name)
    formdata.append("data[bank]", this.state.bank)
    formdata.append("data[company]", this.state.company)
    formdata.append("data[clabe]", this.state.clabe)
    formdata.append("data[currency]", this.state.currency)
    formdata.append("data[aba_routing]", this.state.aba_routing)
    formdata.append("data[send_money]", this.state.send_money.toString())
    formdata.append("data[archive]", this.state.archive.toString())

    this.setState({
      saveClick: true
    })


    if (this.handleCheckFields()) {
      const paramId = this.props.navigation.getParam("id")
      let endPoint = `/bx_block_cfbankcashrecitevoucher4/bank_accounts`
      let method = "POST"
      if (paramId) {
        endPoint += `/${paramId}`
        method = "PUT"
      }
      this.createBankAccountMethodAPI = this.apiCallHandle(
        token,
        endPoint,
        method,
        formdata
      );
    }

  };


  apiCallHandle = (token: token, endPoint: string, method: string, body?: FormData) => {
    const header = {
     token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
