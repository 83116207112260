import React, { Fragment } from 'react';
import { Box, Button, Checkbox, Chip, FormControl, Grid, MenuItem, Radio, Select, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import CfcustomersalescreatecustomerController, { Props } from './CfcustomersalescreatecustomerController.web'
import '../assets/style.css'
import { activity, addrow, close, defaultImg, delrow, external, invoice, meetings, purchase, sales, star, upload, validationerror, vendorbills } from './assets';
import Modal from '@material-ui/core/Modal';
import { Country, State, City } from "country-state-city";
export const configJSON = require("./config");
import Sidebar from '../../../components/src/Sidebar.web';
import { AppHeader } from '../../../components/src/AppHeader.web';
import CustomMultiSelect from "../../../components/src/CustomMultiSelect.web";
 
 
 
interface Option {
    value: string;
    label: string;
}
 
 
 
 
// Customizable Area End
 
export default class Cfcustomersalescreatecustomer extends CfcustomersalescreatecustomerController {
    constructor(props: Props) {
        super(props);
    }
 
    render() {
        // Customizable Area Start
        const { customerType,value,open,addressType,selectedCountry,selectedRegion,selectedCity } = this.state;
        const countries = Country.getAllCountries();
        const regions = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];
    
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className="gridMainLayout">
            <Box className="gridsideBar">
            <Sidebar />
            </Box>
            
            <Box className="gridcontent" >
            <AppHeader pageTitle="Customer" />
            <Box className="parent-div" >
                <Box className='child-div-customer'>
                <Grid container spacing={0} >
                <Grid item sm={3}  className='left-center-align'>
                <Typography data-test-id="navigatingToCustomerListPage" onClick={this.navigateToCustomerListPage} style={{cursor:"pointer"}} className="customer bold-text" >
                    {configJSON.customer} 
                </Typography>
                <Typography className="customer bold-text">
                    <span className='new-normal-text'>/{configJSON.new}</span>
                </Typography>
                </Grid>
                <Grid item sm={9} className='end-center-align relative' >
                <Grid container >
                        
                            <Box className='center-align customer-heading-right border-right' style={{paddingRight:"3px"}} >
                            <Box className='flex'>
                            <img className='customer-heading-img' src={sales}/> 
                            <Box className='left-center-align'>
                            
                            <Typography className='pl-5 grid-right-text'>{configJSON.sales}</Typography>
                            </Box>
                            </Box>
                            </Box>
                            
                        
                            <Box className='center-align customer-heading-right border-right' >
                            <Box className='flex'>
                            <img className='customer-heading-img' src={purchase}/> 
                            <Box className='left-center-align'>
                            
                            <Typography className='pl-5 grid-right-text'>{configJSON.purchase}</Typography>
                            </Box>
                            </Box>
                            </Box>
                        
                            <Box className='center-align customer-heading-right border-right' style={{padding:"0 8px"}}>
                            <Box className='flex'>
                            <img className='customer-heading-img' src={vendorbills}/> 
                            <Box className='left-center-align'>
                            
                            <Typography className='pl-5 grid-right-text'>{configJSON.vendor_bills}</Typography>
                            </Box>
                            </Box>
                            </Box>
                        
                            <Box className='center-align customer-heading-right border-right' >
                            <Box className='flex'>
                            <img className='customer-heading-img' src={invoice}/> 
                            <Box className='left-center-align'>
                            
                            <Typography className='pl-5 grid-right-text'>{configJSON.invoiced}</Typography>
                            </Box>
                            </Box>
                            </Box>
                        
                            <Box className='center-align customer-heading-right' style={{paddingLeft:"8px"}}>
                            <Box className='flex'>
                            <img className='customer-heading-img' src={meetings}/> 
                            <Box className='left-center-align'>
                            
                            <Typography className='pl-5 grid-right-text'>{configJSON.meetings}</Typography>
                            </Box>
                            </Box>
                            </Box>
                        
                            <Box className='left-center-align customer-heading-right' >
                            
                            <img className='customer-heading-img' src={activity}/> <Typography className='pl-5'>{configJSON.activity}</Typography>
                            </Box>
                            
                        
                        </Grid>
                </Grid>
                </Grid>
                </Box>
                
                    {
                        this.mainErrorBox()
                    }
                
                
                <Box className="form-div">
                <Grid container spacing={0} >
                <Grid item sm={10}  >
                    <Box>
                    <Typography className="new_typo">{configJSON.new}</Typography>
                    </Box>
                    <Box>
                    <Typography className='mb-5 customer_type'>{configJSON.customer_type}</Typography>
                    </Box>
                    <Box className='flex'>
                    <Box className={`left-center-align ${customerType === 'a' && 'customer-checked'}`}>
                    <Radio data-test-id="individualRadio"
                        className='individualRadio'
                        checked={customerType === 'a'}
                        onChange={this.handleCustomerType}
                        value="a"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <Typography>{configJSON.individual}</Typography>
                    </Box>
                    <Box className={`left-center-align ${customerType === 'b' && 'customer-checked'}`}>
                    <Radio data-test-id="companyRadio"
                        checked={customerType === 'b'}
                        onChange={this.handleCustomerType}
                        value="b"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }}
                    />
                    <Typography>{configJSON.company}</Typography>
                    </Box>
                    </Box>
                </Grid>
                <Grid item sm={2}  className='end-center-align'>
                <Box className={`${(!this.state.image) && 'imageBox'}`}>
                <img data-test-id="imageRef" className='br-5' onClick={()=>{this.imageRef.current?.click();}} src={this.state.image ? URL.createObjectURL(this.state.image) : defaultImg} height={this.state.image?100:50} width={this.state.image?100:50} />
                </Box>
                    
                    <input data-test-id="imageFile" accept=".jpg, .jpeg, .png" type='file' className="display-none" ref={this.imageRef} name="image" onChange={(e:any)=>this.setState({...this.state,image: e?.target?.files[0]})} />
                </Grid>
                </Grid>
                <Box>
                <Typography className='gen_info' >{configJSON.general_information}</Typography>
                </Box>
                <Grid container spacing={2} className='pt-5'>
                
                <Grid item sm={6} className='grid-padding-right' >
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('customer_name',this.state.customer_name)}`}>{configJSON.customer_name}<span className='required'>*</span></Typography>
                        <TextField data-test-id="customerNameInput" className={`form-input ${this.errorFocusClass('customer_name',this.state.customer_name)}`} 
                        value={this.state.customer_name}
                        onChange={(e:any) => this.setState({...this.state,customer_name:e.target.value})} 
                        name="cname" type="text" />
                        {
                            this.handleErrorMessage('customer_name')
                        }
                        
                
                    </Box>
                </Grid>
                
                <Box className={`${customerType != 'a' && 'display-none'}`}>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('job_position',this.state.job_position)}`}>{configJSON.job_position}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.job_position}
                            onChange={(e:any) => this.setState({...this.state,job_position:e.target.value})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorFocusClass('job_position',this.state.job_position)}`}
                            data-test-id="jobTitleInput"
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:55,
                                    minWidth :505
                                  },
                                },
                            }}
                        >
                            <MenuItem className='select-menu-item' value="Accountant Manager">Accountant Manager</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleErrorMessage('job_position')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('title',this.state.title)}`}>{configJSON.title}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            data-test-id="titleInput"
                            value={this.state.title}
                            onChange={(e:any) => this.setState({...this.state,title:e.target.value})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorFocusClass('title',this.state.title)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                        >
                            <MenuItem className='select-menu-item' value="Miss">Miss</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleErrorMessage('title')
                        }
                        
                    </Box>
                </Grid>
                </Box>
                
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('phone_number',this.state.phone_number)}`}>{configJSON.mobile_number}<span className='required'>*</span></Typography>
                        <TextField className={`form-input ${this.errorFocusClass('phone_number',this.state.phone_number)}`} data-test-id="mobileInput"
                        value={this.state.phone_number}
                        onBlur={(e:any) => this.checkMobileLength(e.target.value,'1')}
                        onChange={(e:any) => this.isMobileNumberValid(e.target.value,'mobile1')}
                        name="mobile_number" type="text" />
                        {
                            this.handleErrorMessage('phone_number')
                        }
                        {
                            (!this.checkMobileLength(this.state.phone_number,'1')) && this.handleErrorMessage('1',true)
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('alternate_phone_number',this.state.alternate_phone_number)}`}>{configJSON.alternative_number}<span className='required'>*</span></Typography>
                        <TextField className={`form-input ${this.errorFocusClass('alternate_phone_number',this.state.alternate_phone_number)}`} data-test-id="altmobileInput"
                        value={this.state.alternate_phone_number}
                        onBlur={(e:any) => this.checkMobileLength(e.target.value,'2')}
                        onChange={(e:any) => this.isMobileNumberValid(e.target.value,'mobile2')}
                        name="alternative-number" type="text" />
                        {
                            this.handleErrorMessage('alternate_phone_number')
                        }
                        {
                            (!this.checkMobileLength(this.state.alternate_phone_number,'2')) && this.handleErrorMessage('2',true)
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('email',this.state.email)}`}>{configJSON.email_address}<span className='required'>*</span></Typography>
                        <TextField className={`form-input ${this.errorFocusClass('email',this.state.email)}`} data-test-id="emailInput"
                        value={this.state.email}
                        onBlur={(e:any) => this.isValidEmail(e.target.value,'email1')}
                        onChange={(e:any) => this.setState({...this.state,email:e.target.value})}
                        name="email" type="text" />
                        {
                            this.handleErrorMessage('email')
                        }
                        {
                            (!this.isValidEmail(this.state.email,'email1')) && this.handleErrorMessage('email1',true)
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className={`main-color tags-multi-select ${this.errorTagsClass('tags')}`}>
                    
                    <CustomMultiSelect
                    classes={{}}
                    options={this.options}
                    required={true}
                    label="Tags"
                    testID=""
                    width="100%"
                    value={this.state.tags}
                    onChangeValue={(value: any) =>
                    this.handleMultiSelect(value, "tags")
                    }
                    />     
                    {
                        this.handleErrorMessage('tags')
                    }
                    </Box>
 
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('language',this.state.language)}`}>{configJSON.language}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            data-test-id="languageInput"
                            IconComponent={this.expandMore}
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.language}
                            onChange={(e:any) => this.setState({...this.state,language:e.target.value})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorFocusClass('language',this.state.language)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                        >
                            <MenuItem className='select-menu-item' value="English (US)">English (US)</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleErrorMessage('language')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} className={`${customerType != 'b' && 'display-none'}`}  >
                    <Box>
                        <Typography className={`${this.errorTextClass('website',this.state.website)}`}>{configJSON.website}<span className='required'>*</span></Typography>
                        <TextField 
                        type="text"
                        name="website"
                        className={`${this.errorFocusClass('website',this.state.website)} form-input`} data-test-id="websiteInput1"
                        value={this.state.website}
                        onBlur={(e:any)=>this.websiteValidation(e.target.value)}
                        onChange={(e:any) => this.setState({...this.state,website:e.target.value})}
                        />
                        {
                            (!this.websiteValidation(this.state.website)) && this.handleWebsiteError()
                        }
                        {
                            this.handleErrorMessage('website')
                        }
                        
                    </Box>
                </Grid>
                
                </Grid>
                <Grid item sm={6} className='grid-padding-left' >
                <Grid item sm={12}  >
                    <Box>
                        <Typography className={`${this.errorTextClass('address1',this.state.address_line1)}`}>{configJSON.address_line1}<span className='required'>*</span></Typography>
                        <TextField className={`form-input ${this.errorFocusClass('address1',this.state.address_line1)}`} data-test-id="addressInput1"
                        value={this.state.address_line1}
                        onChange={(e:any) => this.setState({...this.state,address_line1:e.target.value})}
                        name="address-line1" type="text" />
                        {
                            this.handleErrorMessage('address1')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12}  >
                    <Box>
                        <Typography className='main-color'>{configJSON.address_line2}</Typography>
                        <TextField className={`form-input`} data-test-id="addressInput2"
                        value={this.state.address_line2}
                        onChange={(e:any) => this.setState({...this.state,address_line2:e.target.value})}
                        name="address-line2" type="text" />
                    </Box>
                </Grid>
                
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('selectedCountry',this.state.selectedCountry)}`}>{configJSON.country}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            data-test-id="countryInput"
                            value={selectedCountry}
                            onChange={this.handleCountryChange}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorFocusClass('selectedCountry',this.state.selectedCountry)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            {countries.map((country:any) => (
                            <MenuItem className='select-menu-item' key={country.isoCode} value={country.isoCode}>
                                {country.name}
                            </MenuItem>
                            ))}
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleErrorMessage('selectedCountry')
                        }
                        
                    </Box>
                </Grid>
                
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('selectedRegion',this.state.selectedRegion)}`}>{configJSON.state}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        {
                            this.SelectState(selectedRegion,regions)
                        }
                        
                        </FormControl>
                        {
                            this.handleErrorMessage('selectedRegion')
                        }
                        
                    </Box>
                </Grid>
                
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('selectedCity',this.state.selectedCity)}`}>{configJSON.city}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                        {
                            this.SelectCity(selectedRegion,selectedCity,selectedCountry)
                        }
                        
                        </FormControl>
                        {
                            this.handleErrorMessage('selectedCity')
                        }
                        
                    </Box>
                </Grid>
                
                <Grid item sm={12}  >
                    <Box>
                        <Typography className='main-color'>{configJSON.postal_code}</Typography>
                        <TextField className={`form-input`} data-test-id="postalcodeInput"
                        value={this.state.postal_code}
                        // inputProps={
                        //     { readOnly: true, }
                        // }
                        onChange={(e:any) => this.setState({...this.state,postal_code:e.target.value})}
                        name="postal_code" type="text" />
                    </Box>
                </Grid>
                <Grid item sm={12} className={`${customerType != 'a' && 'display-none'}`}  >
                    <Box>
                        <Typography className={`${this.errorTextClass('website',this.state.website)}`}>{configJSON.website}<span className='required'>*</span></Typography>
                        <TextField className={`form-input ${this.errorFocusClass('website',this.state.website)}`} data-test-id="websiteInput2"
                        value={this.state.website}
                        onBlur={(e:any)=>this.websiteValidation(e.target.value)}
                        onChange={(e:any) => this.setState({...this.state,website:e.target.value})}
                        name="website" type="text" />
                        {
                            this.handleErrorMessage('website')
                        }
                        {
                            (!this.websiteValidation(this.state.website)) && this.handleWebsiteError()
                        }
                    </Box>
                </Grid>
                
                
                <Grid item sm={12}  >
                    <Box>
                        <Typography className={`${this.errorTextClass('company_name',this.state.company_name)}`}>{configJSON.company_name}<span className='required'>*</span></Typography>
                        <TextField className={`form-input ${this.errorFocusClass('company_name',this.state.company_name)}`} data-test-id="companynameInput"
                        value={this.state.company_name}
                        onChange={(e:any) => this.setState({...this.state,company_name:e.target.value})}
                        name="company_name" type="text" />
                        {
                            this.handleErrorMessage('company_name')
                        }
                        
                    </Box>
                </Grid>
                
                </Grid>
                
                
                
                
                
                </Grid>
                </Box>
                <Box className={`tab-parent-div ${value == 2 && 'tab-bottom'}`} >
                <div>
                <Tabs value={value} onChange={this.handleTabChange}>
                <Tab label={configJSON.contact_address} />
                <Tab label={configJSON.sales_purchase} />
                <Tab label={configJSON.accounting} />
                <Tab label={configJSON.internal_notes} />
                
                </Tabs>
                <this.TabPanel value={value} index={0}>
                <Typography className='tab-1 add_more' onClick={this.handleOpen}><b>{this.state.addresses_attributes.length>0 ? configJSON.add_more : configJSON.add}</b></Typography>
                <Box className="p-15">
                <Grid container spacing={3} >
                {
                    this.state.addresses_attributes.length>0 ? this.state.addresses_attributes.map((item:any,index:number)=>{
                      return <Fragment key={index}>
                        <Grid item lg={4}  >
                        <Box className="grid-border">
                        <Grid container spacing={0} className='pt-15' >
                        <Grid item lg={4} className='p-10' >
                            <img src="" height={100} width={100} />
                        </Grid>
                        <Grid item lg={8} className='p-10' >
                            <img src={star} className='grid-image' />
                            <Typography className='contact-text-1'>{item.contact_name}</Typography>
                            <Typography className='contact-text-2'>Nicole appliances</Typography>
                            <Typography className='contact-text-3'>{item.job_position} at Nicoled appliances</Typography>
                            <Typography className='contact-text-3'>{configJSON.phone}: {item.phone_number}</Typography>
                            <Typography className='contact-text-email'>{item.email}</Typography>
                        </Grid>
                        </Grid>
                        <ul className='grid-item-ul ul-create-customer'>
                            <li>Consulting services</li>
                            <li>Electronics appliances vendor</li>
                        </ul>
                        </Box>
                        
                    </Grid>
                      </Fragment>
                    })
                    :
                    <Box style={{padding:"10px 0 0 10px"}}>{this.state.saveClick && this.handleWebsiteError('add_address')}</Box>
                }
                    
                
                
 
                </Grid>
                </Box>
        
                </this.TabPanel>
                <this.TabPanel value={value} index={1}>
                <Grid container spacing={2} className='internal-notes-tab'>
                <Grid item sm={6} className='grid-padding-right' >
                    <Typography className='pb-10 sub-heading'>{configJSON.sales}</Typography>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTabTextClass('saler_name',this.state.salesPurchaseData?.saler_name)}`}>{configJSON.sales_person}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            data-test-id="salesPersonInput"
                            IconComponent={this.expandMore}
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.salesPurchaseData?.saler_name ? this.state.salesPurchaseData?.saler_name : ''}
                            onChange={(e:any) => this.setState({...this.state,salesPurchaseData:{...this.state.salesPurchaseData,saler_name:e.target.value,receipt_reminder:1}})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorTabFocusClass('saler_name',this.state.salesPurchaseData?.saler_name)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem className='select-menu-item' value="Christene Holden">Christene Holden</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleTabErrorMessage('saler_name')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTabTextClass('payment_terms',this.state.salesPurchaseData?.sale_payment_terms)}`}>{configJSON.payment_terms}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="salePaymentTermsInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.salesPurchaseData?.sale_payment_terms ? this.state.salesPurchaseData?.sale_payment_terms : ''}
                            onChange={(e:any) => this.setState({...this.state,salesPurchaseData:{...this.state.salesPurchaseData,sale_payment_terms:e.target.value}})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorTabFocusClass('payment_terms',this.state.salesPurchaseData?.sale_payment_terms)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem className='select-menu-item' value="15 Days">15 Days</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleTabErrorMessage('payment_terms')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTabTextClass('price_list',this.state.salesPurchaseData?.pricelist)}`}>{configJSON.pricelist}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="pricelistInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.salesPurchaseData?.pricelist ? this.state.salesPurchaseData?.pricelist : ''}
                            onChange={(e:any) => this.setState({...this.state,salesPurchaseData:{...this.state.salesPurchaseData,pricelist:e.target.value}})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorTabFocusClass('price_list',this.state.salesPurchaseData?.pricelist)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem className='select-menu-item' value="Public pricelist (USD)">Public pricelist (USD)</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleTabErrorMessage('price_list')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTabTextClass('delivery_method',this.state.salesPurchaseData?.delivery_method)}`}>{configJSON.delivery_method}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="deliveryInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.salesPurchaseData?.delivery_method ? this.state.salesPurchaseData?.delivery_method : ''}
                            onChange={(e:any) => this.setState({...this.state,salesPurchaseData:{...this.state.salesPurchaseData,delivery_method:e.target.value}})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorTabFocusClass('delivery_method',this.state.salesPurchaseData?.delivery_method)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem className='select-menu-item' value="Normal Delivery Charges">Normal Delivery Charges</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleTabErrorMessage('delivery_method')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='pb-10 sub-heading'>
                    {configJSON.point_of_sale}
                    </Box>
                    </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTabTextClass('product_code',this.state.salesPurchaseData?.product_code)}`}>{configJSON.product_code}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="productCodeInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.salesPurchaseData?.product_code ? this.state.salesPurchaseData?.product_code : ''}
                            onChange={(e:any) => this.setState({...this.state,salesPurchaseData:{...this.state.salesPurchaseData,product_code:e.target.value}})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorTabFocusClass('product_code',this.state.salesPurchaseData?.product_code)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem className='select-menu-item' value="90945GHJI">90945GHJI</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleTabErrorMessage('product_code')
                        }
                        <Typography className='max500'>{configJSON.max_8}</Typography>
                    </Box>
                </Grid>
                </Grid>
                <Grid item sm={6} className='grid-padding-left' >
                <Typography className='pb-10 sub-heading'>{configJSON.purchase}</Typography>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTabTextClass('puchase_payment_terms',this.state.salesPurchaseData?.puchase_payment_terms)}`}>{configJSON.payment_terms}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="purchasePaymentTermsInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.salesPurchaseData?.puchase_payment_terms ? this.state.salesPurchaseData?.puchase_payment_terms : ''}
                            onChange={(e:any) => this.setState({...this.state,salesPurchaseData:{...this.state.salesPurchaseData,puchase_payment_terms:e.target.value}})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorTabFocusClass('puchase_payment_terms',this.state.salesPurchaseData?.puchase_payment_terms)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem className='select-menu-item' value="15 Days">15 Days</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleTabErrorMessage('purchase_payment_terms')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTabTextClass('payment_method',this.state.salesPurchaseData?.payment_method)}`}>{configJSON.payment_method}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="paymentMethodInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.salesPurchaseData?.payment_method ? this.state.salesPurchaseData?.payment_method : ''}
                            onChange={(e:any) => this.setState({...this.state,salesPurchaseData:{...this.state.salesPurchaseData,payment_method:e.target.value}})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorTabFocusClass('payment_method',this.state.salesPurchaseData?.payment_method)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem className='select-menu-item' value="Cheque">Cheque</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleTabErrorMessage('payment_method')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box >
                      <Typography className={`${this.errorTabTextClass('reminder',this.state.reminder)}`}>{configJSON.receipt_reminder}<span className='required'>*</span></Typography> 
                    <Box className="relative"> 
                    <TextField id="reminder" value={this.state.reminder} 
                    data-test-id="reminderInput"
                    onChange={this.handleReminder}
                    className={`form-input ${this.errorTabFocusClass('reminder',this.state.reminder)}`} name="reminder" type="text" />
                    {
                        this.handleTabErrorMessage('reminder')
                    }
                    <Typography className='days-before'>{configJSON.daysBefore}</Typography>
                    <Checkbox data-test-id="reminderCheckbox" checked={this.state.reminderCheckbox} onChange={()=>this.setState({...this.state,reminderCheckbox:!this.state.reminderCheckbox})} className="reminder-checkbox" />
                    </Box>
                    </Box>
                        
                        
                        
                    
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTabTextClass('supplier_currency',this.state.salesPurchaseData?.supplier_currency)}`}>{configJSON.supplier_currency}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="supplierCurrencyInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.salesPurchaseData?.supplier_currency ? this.state.salesPurchaseData?.supplier_currency : ''}
                            onChange={(e:any) => this.setState({...this.state,salesPurchaseData:{...this.state.salesPurchaseData,supplier_currency:e.target.value}})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorTabFocusClass('supplier_currency',this.state.salesPurchaseData?.supplier_currency)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem className='select-menu-item' value="OMR">OMR</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleTabErrorMessage('supplier_currency')
                        }
                    </Box>
                </Grid>
                </Grid>
                </Grid>
                </this.TabPanel>
                <this.TabPanel value={value} index={2}>
                <Grid container spacing={2} >
                <Grid item sm={12} className='account_details sub-heading' >
                <Typography>{configJSON.account_details}</Typography>
                </Grid>
                <Grid item sm={12} className='bank-grid' >
                <Grid item sm={5}  >
                    <Typography className='main-color bold-text'>{configJSON.bank}</Typography>
                </Grid>
                <Grid item sm={5}  >
                    <Typography className='main-color bold-text'>{configJSON.account_number}</Typography>
                </Grid>
                <Grid item sm={2} className='end-center-align'>
                    <img onClick={this.addAnotherColumn} src={addrow}/>
                </Grid>
                </Grid>
                <Grid item sm={12} style={{padding:0}}  >
                <hr className='grid-hr'/>
                </Grid>
                {
                    this.state.bankDetails.length> 0 && 
                    this.state.bankDetails.map((detail:any,index:number)=>{
                        return <Fragment key={index}>
                        
                <Grid item sm={12} className='account-grid'  >
                <Grid item sm={5}  className='pl-5'>
                <FormControl id="select-form-control" className="width-100 account-tab-select">
                <Select
                    IconComponent={this.expandMore}
                    data-test-id="bankInput"
                    inputProps={{
                        'aria-label': 'custom select',
                    }}
                    value={detail.bank_name ? detail.bank_name : 'Bank Muscat'}
                    onChange={(e:any)=>this.handleBankDetails(e,index,'bank_name')}
                    label="Select"
                    disableUnderline
                    className="width-100"
                    MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop:52,
                          },
                        },
                    }}
                >
                    <MenuItem value="Bank Muscat">Bank Muscat</MenuItem>
                    
                </Select>
                </FormControl>
                </Grid>
                <Grid item sm={5} className='accountInputBox'  >
                <TextField className={`form-input`} id='accountNoInput' data-test-id="accountNoInput"
                placeholder='Enter Account Number'
                value={detail.account_number}
                onChange={(e:any)=>this.handleBankDetails(e,index,'account_number')} 
                        name="cname" type="text" />
                </Grid>
                <Grid item sm={2} className='end-center-align delrow'>
                <img onClick={()=>this.removeColumn(index)} src={delrow}/>
                </Grid>
                </Grid>
                <Grid item sm={12} style={{padding:0,marginTop:"-10px"}}  >
                <hr className='grid-hr'/>
                </Grid>
                </Fragment>
 
                    })
                }
                <Grid item sm={12} style={{padding:"12px 0"}}  >
                <hr className='grid-hr-2'/>
                </Grid>
                
                </Grid>
                <Grid container spacing={2} className='tab-padding'>
                <Grid item sm={7} className='grid-padding-right' >
                <Grid item sm={12} className='pb-10 sub-heading'><Typography>{configJSON.account_entries}</Typography></Grid>
                <Grid item sm={12} >
                    <Box >
                    <Box className='leftTitle'><Typography className={`${this.errorTabTextClass('account_reievable',this.state.accountingData?.receivable)}`}>{configJSON.account_recievables}<span className='required'>*</span></Typography></Box>
                    <Box className='account-recievables-box relative'>
                    <FormControl id="select-form-control" className="width-70">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="receivableInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.accountingData?.receivable ? this.state.accountingData?.receivable : ''}
                            onChange={(e:any) => this.setState({...this.state,accountingData:{...this.state.accountingData,receivable:e.target.value}})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorTabFocusClass('account_reievable',this.state.accountingData?.receivable)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem className='select-menu-item-2' value="12100 Account Recievable">12100 Account Recievalble</MenuItem>
                            
                        </Select>
                        
                        </FormControl>
                        
                        <img className='pl-5 external-links' src={external} />
                        
                    </Box>
                    {
                        this.handleTabErrorMessage('account_reievable')
                    }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box >
                    <Box className='leftTitle'><Typography className={`${this.errorTabTextClass('account_payable',this.state.accountingData?.payable)}`}>{configJSON.account_payable}<span className='required'>*</span></Typography></Box>
                    <Box className='account-recievables-box relative'>
                    <FormControl id="select-form-control" className="width-70">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="accountPayableInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.accountingData?.payable ? this.state.accountingData?.payable : ''}
                            onChange={(e:any) => this.setState({...this.state,accountingData:{...this.state.accountingData,payable:e.target.value}})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorTabFocusClass('account_payable',this.state.accountingData?.payable)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem className='select-menu-item-2' value="12100 Account Payable">12100 Account Payable</MenuItem>
                            
                        </Select>
                        
                        </FormControl>
                        
                        <img className='pl-5 external-links' src={external} />
                        
                    </Box>
                    {
                        this.handleTabErrorMessage('account_payable')
                    }
                    </Box>
                </Grid>
                <Grid item sm={12} ><Typography className='sub-heading'>{configJSON.general_information}</Typography></Grid>
                
                
                
                </Grid>
 
 
                </Grid>
                <Grid container spacing={2} className='tab-padding'>
                
                <Grid item sm={8}>
                
                <Box >
                <Typography className={`${this.errorTabTextClass('tag_images',this.state.tagImages.length)}`}>{configJSON.tags}<span className='required'>*</span></Typography> 
                <Box className={`${this.state.tagImages.length ? 'tagBox' : 'tagBox1'} left-center-align`}>
                    {this.state.tagImages.length>0 && this.state.tagImages.map((img: any,index:number) => (
                        <Box key={index} className={`relative chipBox ${index == 0 && 'firstChip'}`}>
                            <Chip
                            key={index}
                            label={img.name}
                            
                            />
                            <span data-test-id="removeTagImage" className='handleRemove' onClick={()=>this.handleRemoveFile(index)}>×</span>
                        </Box>
                    
                  ))}
                    
                </Box>
                
                </Box>
                
            
                </Grid>
                <Grid item sm={4} className='tag-box-right-div'>
                    <Button data-test-id="tagImageRef" onClick={()=>{this.tagImagesRef.current?.click();}}  className='drop-files'>{configJSON.drop_files}
                        <img className='ml-10' src={upload}/>
                    </Button>
                    <input data-test-id="tagImageFile" accept=".jpg, .jpeg, .png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf" type='file' multiple className="display-none" ref={this.tagImagesRef} name="tag_image" onChange={(e:any)=>this.handleTagImages(e)} />
                </Grid>
                <Box className='pt-8'>
                {
                    this.handleTabErrorMessage('tag_images')
                }
                </Box>
                
                </Grid>
                </this.TabPanel>
                <this.TabPanel value={value} index={3}>
                <Grid container spacing={2} className='internal-notes-tab'>
                
                <Grid item sm={12} >
                    <Box>
                        <Typography className='main-color'>{configJSON.internal_notes1}</Typography>
 
                        <TextField
                        data-test-id="internalNotesInput"
                        className="width-100"
                        variant="outlined"
                        placeholder={configJSON.internal_notes_placeholder}
                        multiline
                        rows={5}
                        rowsMax={10}
                        value={this.state.note}
                        onChange={(e:any) => this.noteValidation(e.target.value)}
                        />
{(this.state.noteAlert) && <Typography className='noteAlert max500 float-left'>{configJSON.noteNotValid}</Typography>}
                        <Typography className='max500'>{configJSON.max_500}<span className='required'>*</span></Typography>
                    </Box>
                </Grid>
                </Grid>
                </this.TabPanel>
                
                </div>
 
                    </Box>
                
                
                <div>
        
        <Modal
          open={open}
          onClose={this.handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box className="modalBox br-5">
            <Box className="relative">
            <Typography variant="h6" id="modal-title" gutterBottom>
              {configJSON.create_contact}
            </Typography>
            <img onClick={this.handleClose} className='modal-close' src={close} />
 
            </Box>
            
            <Typography variant="body1" id="modal-description" gutterBottom>
            <Box className='flex'>
                    <Box className={`left-center-align ${addressType === 'a' && 'customer-checked'}`}>
                    <Radio
                        className='individualRadio'
                        checked={addressType === 'a'}
                        onChange={this.handleAddressType}
                        value="a"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <Typography>Contact</Typography>
                    </Box>
                    <Box className={`left-center-align ${addressType === 'b' && 'customer-checked'}`}>
                    <Radio
                        checked={addressType === 'b'}
                        onChange={this.handleAddressType}
                        value="b"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }}
                    />
                    <Typography>Other Address</Typography>
                    </Box>
                    </Box>
                    <Grid container spacing={1} >
                <Grid item sm={6} className={`${addressType != 'a' && 'display-none'}`}>
                <Grid item sm={12} >
                    <Box className='flex'>
                    <Box className='leftTitle'><Typography className={`${this.errorContactTextClass('contact_name',this.state.contactData.contact_name)}`}>Contact Name<span className='required'>*</span></Typography></Box>
                    <Box className='contact-form-input'>
                    <TextField className={`form-input ${this.errorContactFocusClass('contact_name',this.state.contactData.contact_name)}`} 
                    data-test-id="cdNameInput"
                    value={this.state.contactData.contact_name ? this.state.contactData.contact_name : ''}
                    onChange={(e:any) => this.setState({...this.state,contactData:{...this.state.contactData,contact_name:e.target.value}})}
                    name="cname" type="text" />
                    {
                        this.state.createClick && this.handleContactErrorMessage('contact_name')
                    }
                    </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='flex'>
                        <Box className='leftTitle'>
                        <Typography className={`${this.errorContactTextClass('contact_title',this.state.contactData.title)}`}>Title<span className='required'>*</span></Typography>
                        </Box>
                        <Box className='contact-form-input'>
                        <FormControl id="select-form-control" className={`width-99 ${this.errorSelectFocusClass('contact_title',this.state.contactData.title)}`}>
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="cdTitleInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.contactData.title ? this.state.contactData.title : ''}
                            onChange={(e:any) => this.setState({...this.state,contactData:{...this.state.contactData,title:e.target.value}})}
                            label="Select"
                            disableUnderline
                            className={`modal-select`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem className='select-menu-item-3' value="Miss">Miss</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.state.createClick && this.handleContactErrorMessage('contact_title')
                        }
                        </Box>
                        
                        
                        
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='flex'>
                        <Box className='leftTitle'>
                        <Typography className={`${this.errorContactTextClass('contact_job_position',this.state.contactData.job_position)}`}>Job Position<span className='required'>*</span></Typography>
                        </Box>
                        <Box className='contact-form-input'>
                        
                        <FormControl id="select-form-control" className={`width-99 ${this.errorSelectFocusClass('contact_job_position',this.state.contactData.job_position)}`}>
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="cdJobInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.contactData.job_position ? this.state.contactData.job_position : ''}
                            onChange={(e:any) => this.setState({...this.state,contactData:{...this.state.contactData,job_position:e.target.value}})}
                            label="Select"
                            disableUnderline
                            className={`modal-select`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem className='select-menu-item-3' value="Accountant Managaer">Accountant Managaer</MenuItem>
                            
                        </Select>
                        </FormControl>
                        {
                            this.state.createClick && this.handleContactErrorMessage('contact_job_position')
                        }
                        </Box>
                        
                    </Box>
                </Grid>
                
                </Grid>
                <Grid item sm={6} className={`${addressType != 'a' && 'display-none'}`}>
                <Grid item sm={12} >
                    <Box className='end-center-align'>
                    <Box className='leftTitle'><Typography className={`${this.errorContactTextClass('contact_email',this.state.contactData.email)}`}>Email Address<span className='required'>*</span></Typography></Box>
                    <Box className='contact-form-input'>
                    <TextField className={`form-input ${this.errorContactFocusClass('contact_email',this.state.contactData.email)}`} 
                    data-test-id="cdEmailInput"
                    value={this.state.contactData.email ? this.state.contactData.email : ''}
                    onBlur={(e:any) => this.isValidEmail(e.target.value,'email2')}
                    onChange={(e:any) => this.setState({...this.state,contactData:{...this.state.contactData,email:e.target.value}})}
                    name="email" type="text" />
                    {
                        (!this.isValidEmail(this.state.contactData.email,'email2')) && this.handleErrorMessage('email2',true)
                    }
                    {
                        this.state.createClick && this.handleContactErrorMessage('contact_email')
                    }
                    </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='end-center-align'>
                        <Box className='leftTitle'>
                        <Typography className={`${this.errorContactTextClass('contact_phone_number',this.state.contactData.phone_number)}`}>Mobile Number<span className='required'>*</span></Typography>
                        </Box>
                        <Box className='contact-form-input'>
                        <TextField className={`form-input ${this.errorContactFocusClass('contact_phone_number',this.state.contactData.phone_number)}`} 
                        data-test-id="cdPhoneInput"
                        value={this.state.contactData.phone_number ? this.state.contactData.phone_number : ''}
                        onBlur={(e:any) => this.checkMobileLength(e.target.value,'3')}
                        onChange={(e:any) => this.isMobileNumberValid(e.target.value,'mobile3')}
                        name="mobile_number" type="text" />
                        {
                            (!this.checkMobileLength(this.state.contactData.phone_number,'3')) && this.handleErrorMessage('3',true)
                        }
                        {
                            this.state.createClick && this.handleContactErrorMessage('contact_phone_number')
                        }
                        </Box>
                        
                        
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='end-center-align'>
                        <Box className='leftTitle' >
                        <Typography className={`${this.errorContactTextClass('contact_alternate_phone_number',this.state.contactData.alternate_phone_number)}`}>Alternative Number<span className='required'>*</span></Typography>
                        </Box>
                        <Box className='contact-form-input'>
                        <TextField className={`form-input ${this.errorContactFocusClass('contact_alternate_phone_number',this.state.contactData.alternate_phone_number)}`} 
                        data-test-id="cdPhone2Input"
                        value={this.state.contactData.alternate_phone_number ? this.state.contactData.alternate_phone_number : ''}
                        onBlur={(e:any) => this.checkMobileLength(e.target.value,'4')}
                        onChange={(e:any) => this.isMobileNumberValid(e.target.value,'mobile4')}
                        name="alternative-number" type="text" />
                        {
                            (!this.checkMobileLength(this.state.contactData.alternate_phone_number,'4')) && this.handleErrorMessage('4',true)
                        }
                        {
                            this.state.createClick && this.handleContactErrorMessage('contact_alternate_phone_number')
                        }
                        </Box>
                        
                        
                    </Box>
                </Grid>
                </Grid>
                
                <Grid item sm={6} className={`${addressType != 'b' && 'display-none'}`}>
                <Grid item sm={12} >
                    <Box className='flex'>
                    <Box className='leftTitle'><Typography className={`${this.errorContactTextClass('contact_address',this.state.contactData.address1)}`}>Address<span className='required'>*</span></Typography></Box>
                    <Box className='contact-form-input'>
                    <TextField className={`form-input ${this.errorContactFocusClass('contact_address',this.state.contactData.address1)}`} 
                    data-test-id="cdAddress1Input"
                    value={this.state.contactData.address1 ? this.state.contactData.address1 : ''}
                    onChange={(e:any) => this.setState({...this.state,contactData:{...this.state.contactData,address1:e.target.value}})}
                    name="address1" type="text" />
                    {
                        this.state.createClick && this.handleContactErrorMessage('contact_address')
                    }
                    </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='flex'>
                        <Box className='leftTitle'>
                        
                        </Box>
                        <Box className='contact-form-input'>
                        <TextField className={`form-input`} 
                        data-test-id="cdAddress2Input"
                        value={this.state.contactData.address2 ? this.state.contactData.address2 : ''}
                        onChange={(e:any) => this.setState({...this.state,contactData:{...this.state.contactData,address2:e.target.value}})}
                        name="address2" type="text" />
                        </Box>
                        
                        
                        
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='flex'>
                        <Box className='leftTitle'>
                        <Typography className={`${this.errorContactTextClass('contact_country',this.state.contactData.country)}`}>Country<span className='required'>*</span></Typography>
                        </Box>
                        <Box className='contact-form-input'>
                        
                        <FormControl id="select-form-control" className={`width-99 ${this.errorSelectFocusClass('contact_country',this.state.contactData.country)}`}>
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="cdCountryInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.contactData.country ? this.state.contactData.country : ''}
                            onChange={(e:any) => this.setState({...this.state,contactData:{...this.state.contactData,country:e.target.value}})}
                            
                            label="Select"
                            disableUnderline
                            className={`modal-select`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            {countries.map((country:any) => (
                            <MenuItem className='select-menu-item-3' key={country.isoCode} value={country.isoCode}>
                                {country.name}
                            </MenuItem>
                            ))}
                            
                        </Select>
                        </FormControl>
                        {
                            this.state.createClick && this.handleContactErrorMessage('contact_country')
                        }
                        </Box>
                        
                    </Box>
                </Grid>
                
                </Grid>
                <Grid item sm={6} className={`${addressType != 'b' && 'display-none'}`}>
                <Grid item sm={12} >
                    <Box className='end-center-align'>
                    <Box className='leftTitle'><Typography className={`${this.errorContactTextClass('contact_city',this.state.contactData.city)}`}>City<span className='required'>*</span></Typography></Box>
                    <Box className='contact-form-input'>
                    <TextField className={`form-input ${this.errorContactFocusClass('contact_city',this.state.contactData.city)}`} 
                    data-test-id="cdCityInput"
                    value={this.state.contactData.city ? this.state.contactData.city : ''}
                    onChange={(e:any) => this.setState({...this.state,contactData:{...this.state.contactData,city:e.target.value}})}
                    name="city" type="text" />
                    {
                        this.state.createClick && this.handleContactErrorMessage('contact_city')
                    }
                    </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='end-center-align'>
                        <Box className='leftTitle'>
                        <Typography className={`${this.errorContactTextClass('contact_state',this.state.contactData.state)}`}>State<span className='required'>*</span></Typography>
                        </Box>
                        <Box className='contact-form-input'>
                        <TextField className={`form-input ${this.errorContactFocusClass('contact_state',this.state.contactData.state)}`} 
                        data-test-id="cdStateInput"
                        value={this.state.contactData.state ? this.state.contactData.state : ''}
                        onChange={(e:any) => this.setState({...this.state,contactData:{...this.state.contactData,state:e.target.value}})}
                        name="state" type="text" />
                        {
                            this.state.createClick && this.handleContactErrorMessage('contact_state')
                        }
                        </Box>
                        
                        
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='end-center-align'>
                        <Box className='leftTitle' >
                        <Typography className={`${this.errorContactTextClass('contact_postal_code',this.state.contactData.postal_code)}`}>Postal Code<span className='required'>*</span></Typography>
                        </Box>
                        <Box className='contact-form-input'>
                        <TextField className={`form-input ${this.errorContactFocusClass('contact_postal_code',this.state.contactData.postal_code)}`} 
                        data-test-id="cdPostalCodeInput"
                        value={this.state.contactData.postal_code ? this.state.contactData.postal_code : ''}
                        onChange={(e:any) => this.setState({...this.state,contactData:{...this.state.contactData,postal_code:e.target.value}})}
                        name="postal_code" type="text" />
                        {
                            this.state.createClick && this.handleContactErrorMessage('contact_postal_code')
                        }
                        </Box>
                        
                        
                    </Box>
                </Grid>
                </Grid>    
                
                
                <Grid item sm={12} >
                    <Box>
                        <Typography ><b>{configJSON.internal_notes1}:</b></Typography>
                        <TextField
                        data-test-id="cdinternalNotesInput"
                        className="width-100"
                        variant="outlined"
                        placeholder="Internal notes..."
                        multiline
                        rows={3}
                        rowsMax={10}
                        value={this.state.contactData.note ? this.state.contactData.note : ''}
                            onChange={(e:any) => this.setState({...this.state,contactData:{...this.state.contactData,note:e.target.value}})}
                        />
                    </Box>
                </Grid>
                </Grid>
            </Typography>
            
            <Box className="end-center-align modal-cancel"  >
            
                <Button onClick={this.handleClose} variant="outlined" className='mr-15'>Cancel</Button>
                
                    <Button variant="contained" data-testid="handle-contact-button" className='create-btn' onClick={this.handleContact}>Create</Button>
                
                
            </Box>
            
              
          </Box>
        </Modal>
      </div>
                
            </Box>
            <Box className='end-center-align form-btn-box'>
                <Button variant="outlined" className='discard-btn' data-testid="discard-button" onClick={this.handleDiscard}>{configJSON.discard}</Button>
                <Button variant="contained" className='save-btn' data-testid="save-button"
                  onClick={() => {
                    this.addCustomer();
                  }} >{configJSON.save}</Button>
                
            </Box>
            </Box>
            </Box>
            
            
            // Customizable Area End
        )
 
    }
        
}