import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { backArrowEnable, backarrow, forwardArrowDisable, forwardarrow, griddownarrow, gridleftarrow, moveBackwardArrowEnable, moveForwardArrowDisable, movebackward, moveforward, paginationarrow } from './assets';
import StorageProvider from "../../../framework/src/StorageProvider";
import { Checkbox, CircularProgress, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  loadMore:boolean;
  page: number;
  per_page: number;
  name:string;
  model:string;
  group:string;
  read_access:boolean;
  write_access:boolean;
  create_access:boolean;
  delete_access:boolean;
  popupAlert:boolean;
  errorMsg:string;
  open: boolean;
  active:any[];
  showFilter: boolean;
  showGroup: boolean;
  showFav: boolean;
  showColumn: boolean
  showCurrentSearch: boolean;
  showAddToDashboard: boolean;
  showSettings: boolean;
  token:string|null;
  showCustomerdata:any[];
  groupbyResponse:any[];
  activeGroup: string; 
  activeFilter: number;
  activeFilterArr: any[];
  filterLabel: string;
  searchKeyword: string;
  isLoading: boolean;
  totalCounts:number;
  totalPage:number;
  globalCheck:boolean;
  checkedArr:any[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingsAccessRightsListViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetaccessrightlistCallId!: string;
  apigetaccessrightGroupbylistCallId!: string;
  apigetaccessrightFilterlistCallId!: string;
  addAccessRightApiCallId: any = "";


  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),

      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      loadMore:false,
      page:1,
      per_page:9,
      name:'',
      model:'',
      group:'',
      read_access:false,
      write_access:false,
      create_access:false,
      delete_access:false,
      popupAlert:false,
      errorMsg:'',
      open: false,
      active:[],
      activeGroup: '',
      activeFilter: -1,
      activeFilterArr: [],
      showFilter: false,
      showColumn: false,
      showGroup: false,
      showFav: false,
      showCurrentSearch: false,
      showAddToDashboard: false,
      showSettings: false,
      showCustomerdata: [],
      groupbyResponse: [],
      token:"",
      filterLabel:"",
      searchKeyword:"",
      isLoading:false,
      totalCounts: 0,
      totalPage:0,
      globalCheck:false,
      checkedArr:[],
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start


    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({...this.state,isLoading:false});
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.errors && apiRequestCallId === this.addAccessRightApiCallId && responseJson.errors[0]) {
        this.setState({...this.state,errorMsg:responseJson.errors[0]})
      }
 
      if (apiRequestCallId && responseJson) {

        if ((apiRequestCallId === this.apigetaccessrightlistCallId) || 
          (apiRequestCallId === this.apigetaccessrightFilterlistCallId) || 
          (apiRequestCallId === this.apigetaccessrightGroupbylistCallId)) {
          this.apiResponse(responseJson)
        }
        if (apiRequestCallId === this.addAccessRightApiCallId) {
                
            if(responseJson.data?.id){
              this.setState({...this.state,popupAlert:true,name:'',model:'',group:'',read_access:false,write_access:false,create_access:false,delete_access:false});
              setTimeout(()=>this.setState({...this.state,open:false,popupAlert:false}),2000)
            }
            
            
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const getloginToken= await StorageProvider.get("TOKEN")
    this.setState({
      token:getloginToken
    })
   await this.getCustomerlistApi(this.state.per_page,this.state.page)
  }

  async componentDidUpdate(prevProps: {}, prevState: S) {
    if ((prevState.activeFilterArr.length !== this.state.activeFilterArr.length)) {
      await this.getAccessRightFilterlistApi('','');
    }
    
    if ((prevState.page !== this.state.page) || (prevState.per_page !== this.state.per_page)) {
      this.state.token && await this.getCustomerlistApi(this.state.per_page,this.state.page)
    }
    if (prevState.activeGroup !== this.state.activeGroup) {
      this.state.activeGroup == 'Group' && await this.getAccessRightGroupbylistApi('group')
      this.state.activeGroup == 'Model' && await this.getAccessRightGroupbylistApi('model')
      !this.state.activeGroup && this.getAccessRightFilterlistApi('','');
    }
    if ((prevState.searchKeyword !== this.state.searchKeyword)) {
      if(this.state.searchKeyword.length>=3){
        await this.getCustomerlistApi(this.state.per_page,1);
      }
      if(this.state.searchKeyword.length==0){
        await this.getCustomerlistApi(this.state.per_page,1);
      }
    }
    
    
  }


  listView = () => {
    if(!(this.state.showCustomerdata.length>0 && (!this.state.isLoading))){
      return 'display-none'
    }else{
      return ''
    }
  }


  loaderBox = () => {
    if(this.state.isLoading || this.state.showCustomerdata.length==0){
      return 'loaderBox'
    }else{
      return 'display-none'
    }
  }


  loaderBoxContent = () => {
    if(this.state.isLoading){
      return <CircularProgress />
    }else if(this.state.showCustomerdata.length==0){
      return <Typography className='bold-text'>No Records Found!</Typography>
    }else{
      return <></>
    }
  }


  checkGroupSelectedListItem = (value:any) => {
    if(this.state.activeGroup == value){
      return 'selected-listItem'
    }else{
      return ''
    }
  }


  checkSelectedListItem = (value:any) => {
    if(this.state.activeFilterArr.includes(value)){
      return 'selected-listItem'
    }else{
      return ''
    }
  }

  filterUI = () => {
    if(this.state.activeFilterArr.length >0){
      return this.state.activeGroup?'filter-box-3 flex':'filter-box-2 flex';
    }else{
      return ''
    }
    
  }

  searchPlaceholder = () => {
    return !(this.state.activeFilterArr.length || this.state.activeGroup) ? 'Search here....' : '';
  }

  searchIconDisplay = () => {
    return this.state.activeFilterArr.length && this.state.activeGroup
  }

  handleFilterLabel = (value:any) => {
    let flabel:string = '';
      switch(value){
        case 0 :  flabel = 'Global'; break;
        case 1 :  flabel = 'Full Access'; break;
        case 2 :  flabel = 'Read Access'; break;
        case 3 :  flabel = 'Write Access'; break;
        case 4 :  flabel = 'Archieved'; break;
        default :  
        break;
        
      }
      return flabel;
  }

  handleGlobalCheckAR = () => {
    let arr:any = [];
    this.state.showCustomerdata?.forEach((user:any) => {
      arr.push(user.id)
    })
    this.setState({...this.state,checkedArr:!this.state.globalCheck?arr:[],globalCheck:!this.state.globalCheck})
  }
  
  handleUserCheckboxAR = (id:any) => {
    if(this.state.checkedArr.includes(id)){
      this.setState({...this.state,globalCheck:false,checkedArr:this.state.checkedArr.filter((i)=> i !== id)})
    }else{
      this.setState({...this.state,globalCheck:(this.state.showCustomerdata.length==(this.state.checkedArr.length+1))?true:false,checkedArr:[...this.state.checkedArr,id]})
    }
  }

  removeActiveFilter = (value:any) => {
    const filteredNumbers = this.state.activeFilterArr.filter(number => number !== value);
    this.setState({...this.state,activeFilterArr:filteredNumbers});
  }

  handleUserActive = (id:any) => {
    if(this.state.active.includes(id)){
      this.setState({...this.state,active:this.state.active.filter((i)=> i !== id)})
    }else{
      this.setState({...this.state,active:[...this.state.active,id]})
    }
  }


  handleActiveFilter = (value:any) => {
    !this.state.activeFilterArr.includes(value) && this.setState({...this.state,activeFilter:value,showFilter:false,activeFilterArr:[...this.state.activeFilterArr,value]});
  }

  handleActiveGroup = (value:any) => {
    this.setState({...this.state,activeGroup:value,showGroup:false});
  }

  loadMore = async(action:number,type:string='',lmore:boolean=false) => {
    this.setState({
      page:action==0?1:this.state.page+action,
      loadMore:lmore
    })
    
  }

  handlePaginationAR = async(action:number) => {
    this.setState({
      per_page:action,page:1
    })
    
  }
 
  apiResponse=(responseJson:any)=>{
    if(responseJson[0]?.permissions?.data?.length>0){
      let res:any = [];
      responseJson.forEach((data:any)=>{
        data.permissions.data.length>0 && res.push(...data.permissions.data)
      })
      
      this.setState({loadMore:false,groupbyResponse:responseJson,showCustomerdata:res,totalCounts:res.length,totalPage:1});
    }else if(responseJson.data){
      (this.state.loadMore && this.state.showCustomerdata?.length>0) ? this.setState({loadMore:false,groupbyResponse:[],showCustomerdata:[...this.state.showCustomerdata,...responseJson.data],totalCounts:responseJson.meta.total_counts,totalPage:responseJson.meta.total_pages}) : this.setState({loadMore:false,groupbyResponse:[],showCustomerdata:responseJson.data,totalCounts:responseJson.meta.total_counts,totalPage:responseJson.meta.total_pages});
    }else if(responseJson.errors){
    window.alert("Invalid Token")
    }
  }
  
  handleGroup = () => {
    this.setState({ ...this.state, showGroup: !this.state.showGroup });
  };

  handleFilter = () => {
    this.setState({ ...this.state, showFilter: !this.state.showFilter });
  };

  handleCurrentSearch = () => {
    this.setState({ ...this.state, showCurrentSearch: !this.state.showCurrentSearch });
  };
  
  handleFavorites = () => {
    this.setState({ ...this.state, showFav: (this.state.showCurrentSearch || this.state.showAddToDashboard) ? true : !this.state.showFav });
  };
  
  handleAddToDashboard = () => {
    this.setState({ ...this.state, showAddToDashboard: !this.state.showAddToDashboard });
  };
  handleAccessRightSettings = () => {
    this.setState({ ...this.state, showSettings: !this.state.showSettings });
  };
  columns: string[] = ["Customer Name", "Phone", "Email", "Salesperson", "Activities", "City", "State", "Country", "VAT/TAX ID", "Next Partner Review", "Partner Level", "Activation", "Tags", "Current Membership Status"];

  isLastPage(): boolean {
    if (!this.state.showCustomerdata.length) {
      return true;
    }
    if (!this.state.activeGroup) {
      return this.state.page === this.state.totalPage;
    }
    return true;
  }

  validateCreateAccessRight = () => {
    if(!(this.state.name && this.state.model && this.state.group)){
      this.setState({...this.state,errorMsg:"All fields are required."})
      return false;
    }else if(!(this.state.read_access||this.state.write_access||this.state.create_access||this.state.delete_access)){
      this.setState({...this.state,errorMsg:"Select atleast one permission."})
      return false;
    }else{
      this.setState({...this.state,errorMsg:""})
      return true;
    }
    
  }

  handleCreateAccessRight = () => {
    if(!this.validateCreateAccessRight()){
      return false;
    }
    let formdata:any = new FormData();
      formdata.append("[data][name]", this.state.name);
      formdata.append("data[model]", this.state.model);
      formdata.append("[data][group]", this.state.group);
      formdata.append("[data][read_access]", this.state.read_access);
      formdata.append("[data][write_access]", this.state.write_access);
      formdata.append("[data][create_access]", this.state.create_access);
      formdata.append("[data][delete_access]", this.state.delete_access);
      const header = {
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addAccessRightApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_admin/permissions'
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      setTimeout(()=>this.getCustomerlistApi(this.state.per_page,this.state.page), 1000)
      return true;
  }
  
  getCustomerlistApi = async (per_page:number,page1:number) => {
    this.setState({...this.state,isLoading:true,globalCheck:false,checkedArr:[],page:page1});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };

    this.apigetaccessrightlistCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/permissions?per_page=${per_page}&page=${page1}&search=${this.state.searchKeyword}${this.state.activeGroup=='Group' ? '&group=group':this.state.activeGroup=='Model' ? '&model=model':''}${this.state.activeFilterArr.includes(0)?'&global=global':''}${this.state.activeFilterArr.includes(1)?'&full_access=full_access':''}${this.state.activeFilterArr.includes(2)?'&read_access=read_access':''}${this.state.activeFilterArr.includes(3)?'&write_access=write_access':''}${this.state.activeFilterArr.includes(4)?'&archived=archived':''}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAccessRightGroupbylistApi = async (value:string) => {
    this.setState({...this.state,isLoading:true,globalCheck:false,checkedArr:[],page:1,searchKeyword:''});
    const url_filter = `${this.state.activeFilterArr.includes(0)?'&global=global':''}${this.state.activeFilterArr.includes(1)?'&full_access=full_access':''}${this.state.activeFilterArr.includes(2)?'&read_access=read_access':''}${this.state.activeFilterArr.includes(3)?'&write_access=write_access':''}${this.state.activeFilterArr.includes(4)?'&archived=archived':''}`; 
    const url_params = `?${value}=yes${url_filter}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };

    this.apigetaccessrightGroupbylistCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/permissions${url_params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAccessRightFilterlistApi = async (key:string,value:string) => { 
    this.setState({...this.state,isLoading:true,globalCheck:false,checkedArr:[],page:1,searchKeyword:''});
    const pages = this.state.activeGroup ? `${this.state.activeGroup=='Group' ? '&group=group':this.state.activeGroup=='Model' ? '&model=model':''}` : `per_page=${this.state.per_page}&page=1`
    const url_params = `?${pages}${this.state.activeFilterArr.includes(0)?'&global=global':''}${this.state.activeFilterArr.includes(1)?'&full_access=full_access':''}${this.state.activeFilterArr.includes(2)?'&read_access=read_access':''}${this.state.activeFilterArr.includes(3)?'&write_access=write_access':''}${this.state.activeFilterArr.includes(4)?'&archived=archived':''}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };
    this.apigetaccessrightFilterlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/permissions${url_params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClose = () => {
      this.setState({ ...this.state,open: false,name:'',model:'',group:'',read_access:false,write_access:false,create_access:false,delete_access:false });
  };

  handleOpen = () => {
        this.setState({ ...this.state,open: true });
  };
  
  expandMore = () => {
    return <img className='expandMoreImg' height={7} src={paginationarrow}/>;
  }

  movebackwardFunc = () => {
    return this.state.page==1 ? movebackward : moveBackwardArrowEnable;
  }

  backarrowFunc = () => {
    return this.state.page==1 ? backarrow : backArrowEnable;
  }

  forwardarrowFunc = () => {
    return this.isLastPage()? forwardArrowDisable:forwardarrow;
  }

  moveforwardFunc = () => {
    return this.isLastPage()? moveForwardArrowDisable:moveforward;
  }

  popupAlertFunc = () => {
    if(!(this.state.popupAlert||this.state.errorMsg)){
      return 'display-none'
    }else{
      return ''
    }
  }

  defaultViewData = () => {
    return ((!this.state.activeGroup) && this.state.showCustomerdata.length> 0 && (!this.state.isLoading))
  }

  handleGroupIndexZero = (item:any,index1:number,index:number) => {
    if(index1==0){
      return <TableRow className='table-custom-row' data-test-id="table-custom-row" onClick={() => this.handleUserActive(index)} >
                <TableCell >
                <IconButton
                    className='arrow-icon-button'
                    aria-label="expand row"
                    size="small"
                >
                    <img src={this.state.active.includes(index) ? griddownarrow : gridleftarrow} />
                </IconButton>
                </TableCell>
                <TableCell className='table-column-bold' component="th" scope="row" colSpan={7}>
                {
                    item.key
                }
                {
                    ` (${item.permissions.data.length})`
                }
                </TableCell>
                
            </TableRow>
    }else{
      return <></>
    }
  }

  expandTableRowAR = (user:any,classes:any) => {
    return <TableRow>
                <TableCell>
                <Checkbox 
                className={classes.accessrightlistviewCheck}
                checked={this.state.checkedArr.includes(user?.id) ? true : false}  
                onChange={()=>this.handleUserCheckboxAR(user?.id)}
                /></TableCell>
                <TableCell className={classes.accessrightlistviewTabletext}>{user.attributes.name}</TableCell>
                    <TableCell className={classes.accessrightlistviewTabletext}>{user.attributes.model}</TableCell>
                    <TableCell className={classes.accessrightlistviewTabletext}>{user.attributes.group}</TableCell>
                    <TableCell className={classes.accessrightlistviewTabletext}><Checkbox checked={user.attributes.read_access} className={classes.accessrightlistviewCheck} /></TableCell>
                    <TableCell className={classes.accessrightlistviewTabletext}><Checkbox checked={user.attributes.write_access} className={classes.accessrightlistviewCheck} /></TableCell>
                    <TableCell className={classes.accessrightlistviewTabletext}><Checkbox checked={user.attributes.create_access} className={classes.accessrightlistviewCheck} /></TableCell>
                    <TableCell className={classes.accessrightlistviewTabletext}><Checkbox checked={user.attributes.delete_access} className={classes.accessrightlistviewCheck} /></TableCell>

            </TableRow>
  }

  // Customizable Area End
}
